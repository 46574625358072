import React from 'react';
import './Teaser.scss';
import ScrollAnimation from 'react-animate-on-scroll'
import TeaserImage from './teaser.png'

export default function Teaser(props) {

    return (
        <div className={'bg-slider teaser'} id={'teaser'}>

            <div className="container-fluid">
                <div className={'row'}>
                    <div className={'col-lg-2 d-none d-lg-block'}>&nbsp;</div>
                    <div className={'col-lg-8 col-md-12'}>

                        <p className={'teaser-text-2'}>
                            <ScrollAnimation animateIn='slideInUp' animateOnce={true} delay={100}>
                                "Gemeinsam handeln für ein neues Wir-Gefühl"
                                </ScrollAnimation>
                        </p>


                        <div className={'teaser-image'}>
                            <ScrollAnimation animateIn='slideInUp' animateOnce={true} delay={400}>
                                <img src={TeaserImage} />
                            </ScrollAnimation>
                        </div>


                    </div>
                    <div className={'col-lg-2 d-none d-lg-block'}>&nbsp;</div>
                </div>
            </div>
        </div>
    )

}