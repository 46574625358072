import React from 'react';
import './Family.scss';
import FamilyPicture from './family-picture.JPG';

export default function About(props) {

    return (
        <div className={'box '} id={'family'}>
            <div className="container-fluid">
                <div className={'row justify-content-around'}>
                    <div className={'col-md-12 col-lg-8'}>
                        <div className={'title'}>Hobby und Familie</div>
                        <div className={'row'}>
                            <div className={'col-md-7'}>
                                <p className="text-justify">
                                    In meiner Freizeit bin ich stellvertretender Vorsitzender des Orts- und Bürgervereins in Kamperfehn und Mitglied des Stadtrats in Friesoythe. Ich kenne daher die kommunalpolitische Arbeit sowie die anstehen­den Herausforderungen, mit denen sich viele Gemeinden im Landkreis Cloppenburg befassen. Wenn es die Zeit zulässt, laufe ich gern und bin als Sohn einer Handwerkerfamilie leidenschaftlicher Heimwerker. Zudem begleiten und unterstützen wir unsere Kinder gerne in ihrem Vereinsleben.
                                </p>
                            </div>
                            <div className={'col-md-5 pr-3'}>
                                <img src={FamilyPicture} className={'family-picture'} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}