import React from 'react';


import MainPage from './MainPage';
import Imprint from './Imprint';

import { HashRouter, Route } from "react-router-dom";


import "animate.css/animate.min.css";

import './app.scss'

/*import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'*/

import 'bootstrap';

//library.add(fas, fal, far, fad)

function App() {
  return (
    <div className="App">

      <HashRouter>

        <Route exact path="/" component={MainPage} />
        <Route exact path="/impressum" component={Imprint} />

      </HashRouter>


    </div>
  );
}

export default App;

//<Why />