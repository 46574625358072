import React from 'react';
import './Theses.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight } from '@fortawesome/pro-solid-svg-icons'
export default function Theses(props) {

    const Thesis = (props) => {
        let id = 'thesis_' + Math.round(Math.random() * 1000);
        return (
            <div className="card mb-2" key={id}>
                <div className="card-header p-0" id={'header_' + id}>
                    <h2 className="mb-0">
                        <button className="btn btn-link" type="button" data-toggle="collapse" data-target={'#' + id} aria-expanded={props.show ? 'true' : 'false'} aria-controls={id}>
                            <FontAwesomeIcon fixedWidth icon={faCaretRight} /> {props.title}
                        </button>
                    </h2>
                </div>
                <div id={id} className={"collapse " + (props.show ? 'show' : '')} aria-labelledby={'header_' + id} data-parent="#accordionThesis">
                    <div className="card-body text-justify">
                        {props.children}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className={'box theses'} id={'politics'}>

            <div className="container-fluid">

                <div className={'row justify-content-around'}>
                    <div className={'col-lg-8 col-md-12'}>

                        <div className={'title-light'}>Politik - Wofür ich mich einsetze</div>

                        <p className={'text-light'}>Es ist schwer, die Vielzahl an Themen überschaubar darzustellen. Die folgenden Themen stellen daher nur Schwerpunkte meiner politischen Ziele dar.</p>

                        <div className="accordion" id="accordionThesis">

                            <Thesis title={'Bauen und Wohnen'} show>
                                <p>Wir haben in der gesamten Gemeinde Garrel im Gegensatz zu anderen Regionen in Deutschland einen großen Bedarf an Bauland und Wohnungen. Das ist zunächst ein erfreulicher Zustand. Es zeigt, dass Garrel und seine Ortschaften als Wohn- und Lebensort weiterhin begehrt sind. Aufgrund des begrenzten Angebots an Wohnraum sehen sich viele interessierte Bürger gezwungen, ihren Lebensmittelpunkt in andere Kommunen zu verlegen bzw. dort aufzubauen. Eine solche Entwicklung kenne ich aus eigener Erfahrung in der Ortschaft Kamperfehn, die wir nunmehr mit der Stadtverwaltung und dem Rat durch Ausweisung von Bauland aufhalten konnten. </p>
                                <p>Ich möchte aber Ihren Kindern und den Menschen, die hier arbeiten, auch die Möglichkeit geben, in der Gemeinde Garrel zu leben. Deshalb strebe ich eine Kombination aus <b>neuen Baugebieten</b>, einer <b>verbesserten Innenverdichtung</b> sowie einem <b>Lückenschluss von Siedlungen</b> im Außenbereich an. Als Bürgermeister würde ich aktiv und kreativ handeln, um eine bedarfsgerechte und zukunftsorientierte Entwicklung zu ermöglichen.</p>
                            </Thesis>

                            <Thesis title={'Mehr Dialog mit Industrie, Handel, Handwerk und Landwirtschaft'}>
                                <p>Die Gemeinde Garrel kann auf eine starke wirtschaftliche Entwicklung zurückblicken und weist einen hohen Bestand an mittelständischen - familiär geführten - Betrieben auf. Eine gute Wirtschaftsstruktur trägt letztlich auch zu einer Verbesserung des kommunalen Gemeinwesens bei. Neben <b>Neuansiedlungen</b> (Bereitstellung von ausreichend Gewerbeflächen) sollte der Fokus auf die <b>Bestandspflege</b> gelegt werden. </p>
                                <p>Im Rahmen eines <b>„Runden Tisches“</b> werde ich mich mit den Vertretern der jeweiligen Branchen regelmäßig austauschen. So kann die Gemeindeverwaltung frühzeitig auf mögliche Probleme reagieren.</p>
                            </Thesis>

                            <Thesis title={'Ortschaften stärken'}>
                                <p>Für mich ist es selbstverständlich, dass die Ortschaften in eine positive Entwicklung der gesamten Gemeinde miteingebunden werden. Nur so kann ein gemeinsames <b>Wir-Gefühl</b> entstehen!</p>
                                <p>Als Bürgermeister werde ich die Vertreter der Ortschaften vor Ort aufsuchen und mit ihnen Wünsche oder auch Probleme erörtern. Ferner möchte ich <b>regelmäßig Versammlungen und Bürgersprechstunden</b> abhalten, um alle Bürgerinnen und Bürgern der Ortschaften aktiv in die Politik mit einzubeziehen. </p>
                            </Thesis>

                            <Thesis title={'Ein offenes Rathaus mit hoher Bürgerbeteiligung'}>
                                <p>Der Rat der Gemeinde Garrel hat Entscheidungen zu treffen und Beschlüsse im Sinne der Bürgerinnen und Bürger zu fassen. Einsame Entscheidungen passen nicht in meine Vorstellung eines <b>transparenten und offenen Ratshauses</b>. Vielmehr sind Sie über anstehende Planungen umfassend zu informieren sowie in die anstehenden Vorhaben einzubeziehen. </p>
                                <p>Daher halte ich die Einführung von <b>Bürgerversammlungen</b>, <b>Sprechstunden</b> vor Ratssitzungen sowie <b>gemeinsame Arbeitsgruppen</b> für erforderlich. Ich freue mich auf die Zusammenarbeit mit Ihnen.</p>
                            </Thesis>

                            <Thesis title={'Verbesserung der Verkehrssituation'}>
                                <p>Seit Jahren nimmt der Straßenverkehr im Innenbereich von Garrel zu. Die bestehende Infrastruktur kann die mit der positiven Entwicklung Garrels einhergehende Verkehrsbelastung nicht mehr aufnehmen.</p>
                                <p>Ich halte daher den <b>Bau einer Entlastungsstraße</b> für unumgänglich, um den Verkehr im Kernbereich deutlich zu reduzieren. Auf dieser Grundlage möchte ich weitere Maßnahmen zur Verbesserung der Verkehrssituation, Attraktivität und Wohnqualität im Innenbereich umsetzen.</p>
                            </Thesis>

                        </div>

                        <p className={'text-light'}>Möchten Sie mehr über meine politischen Themen und Vorstellungen erfahren, nehmen Sie bitte unbedingt Kontakt zu mir auf.</p>


                    </div>
                </div>
            </div>
        </div>
    )

}