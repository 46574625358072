import React, { useState, useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import './Carousel.scss'
import thomasRathaus from './thomas_rathaus.png'
import thomasRathausPortrait from './thomas_rathaus_portrait.png'
import family from './family2.JPG'
import familyPortrait from './family2_portrait.jpg'
import thomas from './thomas3.png'

export default function CarouselPage(props) {

  const size = useWindowSize();

  return (
    <div className="" >

      <Carousel infiniteLoop interval={5000} showThumbs={false} showArrows={true} showIndicators={false} showStatus={false} useKeyboardArrows autoPlay >
        <div className={'bg-slider'} style={{ height: size.height - 80 }}>

          <div className="container-fluid" style={{ height: '100%' }}>
            <div className="row" style={{ height: '100%' }}>

              <div className="col-lg-8">
                <div className={'slide-text'}><span className={'slide-text-bg'}>Ihr Bürgermeister</span></div>
                <div className={'slide-text'}><span className={'slide-text-bg'}>Für die gemeinde</span></div>
                <div className={'slide-text'}><span className={'slide-text-bg'}>Garrel</span></div>
              </div>

              <div className="col-lg-4">
                <img src={thomas} className={'carousel-slide-1-image'} />
              </div>

            </div>
          </div>


        </div>
     
        <div style={{ height: size.height - 80 }}>
          <div className={'image-landscape'} >
            <img src={family} alt={'Thomas Höffmann mit seiner Familie'}  />
          </div>
          <div className={'image-portrait'} >
            <img src={familyPortrait} alt={'Thomas Höffmann mit seiner Familie'}  />
          </div>
        </div>
        
        <div style={{ height: size.height - 80 }}>
          <div className={'image-landscape'} >
            <img src={thomasRathaus} alt={'Thomas Höffman vor dem Rathaus in Garrel'}  />
          </div>
          <div className={'image-portrait'} >
            <img src={thomasRathausPortrait} alt={'Thomas Höffman vor dem Rathaus in Garrel'}  />
          </div>
        </div>
      </Carousel>
    </div>
  );

}

// Hook
// thx https://usehooks.com/useWindowSize/
function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
