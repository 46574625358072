import React from 'react';
import signature from './signature2.png'
import './About.scss';
import aboutImage from './about.jpg'

export default function About(props) {

    return (
        <div className={'box'} id={'about'}>
            <div className="container-fluid">
                <div className={'row justify-content-around'}>
                    <div className={'col-xl-8 col-lg-12 col-md-12'}>
                        <div className={'title'}>Über mich - Thomas Höffmann</div>
                        <div className={'row'}>
                            <div className={'col-md-6'}>
                                <p className="text-justify">Ich bin 46 Jahre alt, verheiratet und habe mit meiner Frau Martina zwei Kinder. Unsere Tochter Lea ist 18 Jahre und unser Sohn Tom ist 14 Jahre alt. Wir kommen aus der kleinen Ortschaft Kamperfehn in der Gemeinde Friesoythe. Da unser Tom einige Jahre in Garrel Fußball gespielt hat, kennen uns einige vielleicht vom Sportplatz. In dieser Zeit haben wir die Einwohner der Gemeinde Garrel stets als nette, offene und motivierte Menschen kennengelernt.</p>
                                <img src={aboutImage} className={'about-image d-lg-none'} />
                                <p className="text-justify">Beruflich bin ich als Referatsleiter für den Senator für Inneres tätig und unterstütze mit meinen Untersuchungen den Staatsrat. Ich bin ausgebildeter Steuerfachangestellter und habe die Studiengänge zum Dipl.-Betriebswirt sowie zum Dipl.-Verwaltungswirt abschließen können. Ferner konnte ich berufsbegleitend einen verwaltungswissenschaftlichen Masterabschluss erwerben. Vor meiner Tätigkeit beim Senator für Inneres habe ich viele Jahre u. a. für die Polizei - insbesondere für das Kommissariat Wirtschaftskriminalität und Betriebswirtschaftliche Analyse - sowie den Rechnungshof in Bremen gearbeitet.</p>
                                <p className="text-justify">Für mich muss die Politik eines Bürgermeisters ehrlich und nah am Bürger sein. Er sollte aktiv sein, fachlich kompetent und auch innovativ handeln. Ihm sollte bewusst sein, dass er die zur Verfügung stehenden Mittel treuhänderisch verwaltet. Mit seinem Handeln sollte er die Gemeinde zukunftsorientiert voranbringen. Ich sehe viele Herausforderungen, aber auch große Chancen für die gesamte Gemeinde Garrel!</p>
                            </div>
                            <div className={'col-md-6'}>
                                <img src={aboutImage} className={'about-image d-none d-lg-block'} />
                                <p className="text-justify">Gerne können wir uns über Ihre Anliegen und Hinweise austauschen. Also scheuen Sie sich nicht, mich anzusprechen. Ich möchte ein Bürgermeister sein, der zuhört, Probleme erkennt und handelt. Ich freue mich auf Sie.</p>
                                <div className={'text-right'}>
                                    <img src={signature} className={'signature'} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}