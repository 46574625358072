import React from 'react'
import Header from './Header/Header'
import Carousel from './Carousel/Carousel'
import About from './About/About'
import Teaser from './Teaser/Teaser'
import Theses from './Theses/Theses';
import Why from './Why/Why';
import Family from './Family/Family';
import Contact from './Contact/Contact';
import Imprint from './Imprint/Imprint';

export default () => {

  return (
    <div>

      <Header />

      <Carousel />

      <About />

      <Teaser />

      <Theses />

      <Family />

      <Imprint />
    </div>
  )

}