import React from 'react';
import './Imprint.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faMobile } from '@fortawesome/pro-regular-svg-icons'

export default function Theses(props) {

    return (
        <div className={'box imprint bg-slider'} id={'imprint'}>
            <div className="container-fluid">
                <div className={'row justify-content-around'}>
                    <div className={'col-lg-8 col-md-12'}>
                        <div className={'title'}>Kontakt</div>

                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-12">
                                    <div style={{fontSize: '20pt'}}>Thomas Höffmann</div>
                                    <div className={'mb-3'}><i>Kompetent. Innovativ. Bürgernah.</i></div>
                                    <div>Pappelweg 40a<br />
                                        26169 Friesoythe</div>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-12">
                                    <div style={{ width: '90px', display: 'inline-block' }}><b><FontAwesomeIcon fixedWidth icon={faMobile} /> Mobil:</b></div>
                                    <div style={{ display: 'inline-block' }}>0163 / 2849667</div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div style={{ width: '90px', display: 'inline-block' }}><b><FontAwesomeIcon fixedWidth icon={faEnvelope} /> E-Mail:</b></div>
                                    <div style={{ display: 'inline-block' }}><a href='mailto:info@thomashoeffmann.de=subject=Bürgermeisterwahl Gemeinde Garrel 2019'>info@thomashoeffmann.de</a></div>
                                </div>
                            </div>
                        </div>

                        <p className={'mt-5'}><a href='/#impressum'>Zum Impressum</a></p>

                    </div>
                </div>
            </div>
        </div>
    )

}