import React from 'react';
import './Header.scss'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export default function Header(props) {

    return (
        <div className={'header-space'}>
            <div className={'header container-fluid'}>
                <div className={'row justify-content-md-center'}>
                    <div className={'col-auto col-lg-4'}>
                        <div className={'logo'}>
                            <div className={'logo-name'}>Thomas Höffmann</div>
                            <div className={'logo-abilities'}>Kompetent. Innovativ. Bürgernah.</div>
                        </div>
                    </div>
                    <div className={'col-auto d-none d-lg-block'}>
                        <div className={'navigation'}>

                            <div className={'nav-item'}>
                                <AnchorLink  href='#about'>Über mich</AnchorLink >
                            </div>
                            <div className={'nav-item'}>
                                <AnchorLink  href='#politics'>Politik</AnchorLink >
                            </div>
                            <div className={'nav-item'}>
                                <AnchorLink  href='#family'>Familie</AnchorLink >
                            </div>
                            <div className={'nav-item'}>
                                <AnchorLink  href='#contact'>Kontakt</AnchorLink >
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

}